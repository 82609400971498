import React                                                                                      from 'react';
import {Modal} from "../../../libs/uiKit/modal";
import {StyledMessagesWrapper} from "./styles";
import {Text3, Text4} from "../../../libs/uiKit/globalStyles";
import {StyledButtonsGroup} from "../../pages/invoice/widgets/sendPayLinkModal/styles";
import Checkbox from "../../../libs/uiKit/checkbox";
import Button from "../../../libs/uiKit/button";
import useNotification from "../notifier/ui/hooks/useNitification";
import {
  MarkMessageMutation, MarkMessageMutationVariables,
  Maybe,
  Message,
  MessagesQuery,
  MessagesQueryVariables
} from "../../../libs/api/graphqlTypes";
import {useQuery} from "@apollo/react-hooks";
import {MESSAGES} from "../../../libs/api/queries";
import {useMutation} from "@apollo/client";
import {MARK_MESSAGE} from "../../../libs/api/commands";

const MessagesModal: React.FC = () => {

  const [stateIsOpen, setIsOpen] = React.useState<number | null>(null);
  const [stateIsRead, setIsRead] = React.useState<boolean>(false);

  const [stateMessages, setMessages] = React.useState<Message[]>([]);

  const {data: dataMessages, loading: loadingMessages, error: errorMessages} = useQuery<MessagesQuery, MessagesQueryVariables>(MESSAGES);
  const [markMessage, {loading: loadingMarkMessage}] = useMutation<MarkMessageMutation, MarkMessageMutationVariables>(MARK_MESSAGE);
  const messages = dataMessages?.messages;
  const messageData = (messages||[]).find(message => message?.id === stateIsOpen);

  React.useEffect(() => {
    if (!messages || !messages[0]) return;
    setIsOpen(messages[0].id);
    setMessages(messages);
  }, [messages])

  const checkNextMessage = (id: number) => {
    const nextMessagesState = stateMessages.filter(message => message?.id !== id);
    const nextMessageId: number | undefined = nextMessagesState[0]?.id
    if (stateIsRead) setIsRead(false);
    if (!nextMessageId) {
      setIsOpen(null)
      setMessages(nextMessagesState);
      return;
    }
    setIsOpen(nextMessageId);
    setMessages(nextMessagesState);
  };

  const onCloseHandler = () => {
    if (!stateIsOpen) return;
    if (!stateIsRead) return checkNextMessage(stateIsOpen);
    markMessage({variables: {
        id: stateIsOpen
      }})
        .then(() => checkNextMessage(stateIsOpen))
  }

  if (errorMessages) return null;

  return (
    <Modal isOpen={!!stateIsOpen} header='Оповещение' onClose={onCloseHandler} isLoading={loadingMessages}>
      <StyledMessagesWrapper>
        <Text3 dangerouslySetInnerHTML={{__html: messageData?.text || ""}}/>
        <StyledButtonsGroup className='messagesButton'>
          <Checkbox checked={stateIsRead} label="Подверждаю получение" onChange={({target: {checked}}) => setIsRead(checked)}/>
          <Button isLoading={loadingMarkMessage} appearance="filled" onClick={onCloseHandler}>ОК</Button>
        </StyledButtonsGroup>
      </StyledMessagesWrapper>

    </Modal>
  )
};

export default React.memo(MessagesModal);