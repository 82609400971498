import styled from "styled-components";

export const StyledMessagesWrapper = styled.div`
  > p {
    margin-bottom: 3rem;
  }
  
  .messagesButton {
    margin-top: 3rem;
  }
`;
